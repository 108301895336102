import sha256 from 'sha256';
import axios from 'axios';

const appId = "ULFS1187B491613F9C6C0";
const appToken = "88813da5c9e5fbc9d29137e349ac611f209db60a1e9e9a2ab5b39babf7f1481c8f45c595d1725231.JNMV1187B491613F9C715";
const salt = "3PQL1187B491613F9C6EE";
const transactionRedirectUrl = "https://viswamcampus.edu.lk/payment-success/";

var paymentUrl = "https://merchant-api-live-v2.onepay.lk/api/ipg/gateway/request-transaction/?hash=";

export const paymetRequest = (requestData) => {
    return (dispatch) => {
        var data = JSON.stringify({
            "amount": parseFloat(requestData.amount),  //only alow LKR amouts
            "app_id": appId,
            "reference": requestData.reference,
            "customer_first_name": requestData.fn,
            "customer_last_name": requestData.ln,
            "customer_phone_number": requestData.phone, //please enter number with +94
            "customer_email": requestData.email,
            "transaction_redirect_url": transactionRedirectUrl
        });
        const hash_obj = data + salt;
        const gen_hash = sha256(hash_obj);
        //paymentUrl = paymentUrl + gen_hash;

        //console.log("DATA", data);
        //console.log("HASH OBJ", hash_obj);
        //console.log("HASH", gen_hash);
        //console.log("URL", paymentUrl);

        //test codes
        var config = {
            method: 'put',
            url: `https://merchant-api-live-v2.onepay.lk/api/ipg/gateway/request-transaction/?hash=${gen_hash}`,
            headers: {
                'Authorization': appToken,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                //console.log(response);
                dispatch({
                    type: "paymetRequest",
                    payload: {
                        redirect: response.data.data.gateway.redirect_url
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });

        //test codes end

        // dispatch({
        //     type: "paymetRequest",
        //     payload: {
        //         redirect: "https://www.facebook.com/"
        //     }
        // })
    }
};