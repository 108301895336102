import React, { Component } from 'react';
import { Grid, Form, Header, Button, Segment, Image, Message, Label, Input, Breadcrumb, Divider } from "semantic-ui-react";
import { connect } from "react-redux";

import { paymetRequest } from './store/actions/paymentActions'

class PaymentComponent extends Component {
    state = {
        email: "",
        fn: "",
        ln: "",
        phone: "",
        amount: "",
        reference: Math.random().toString(36).slice(2) || "",
        buttonDisabled: false,
    }

    onSubmit() {
        console.log("Submitted", this.state);
        this.props.paymetRequest({
            email: this.state.email,
            fn: this.state.fn,
            ln: this.state.ln,
            phone: this.state.phone,
            amount: this.state.amount,
            reference: this.state.reference,
        });
        this.setState({
            buttonDisabled: true,
        });
        setTimeout(() => {
            this.setState({
                reference: Math.random().toString(36).slice(2),
                buttonDisabled: false
            })
        }, 5000);
    }

    render() {
        //console.log({ "STATE": this.state }, { "PROPS": this.props });
        var redirect = this.props.payments.redirect;

        if (redirect) {
            window.location.replace(redirect);
        }

        return (
            <Grid className='gridContainer' textAlign='center' style={{ height: '100vh', margin: '50px 0px 50px 0px' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        <Image src='https://viswamcampus.edu.lk/wp-content/uploads/elementor/thumbs/Logo-p8fgd7ssegwwcpy1bb2bq74hf40mb3xzehsvc5ttkw.png' /> Secure Payment Gateway
                        <Header.Subheader>Viswam Campus (PVT) LTD.</Header.Subheader>
                    </Header>
                    <Breadcrumb>
                        <Breadcrumb.Section link>Viswamcampus.edu.lk</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link>Local Payments</Breadcrumb.Section>
                    </Breadcrumb>
                    <Divider />
                    <Form size='large' onSubmit={this.onSubmit.bind(this)}>
                        <Segment stacked>
                            <Form.Input value={this.state.email} onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} required name='email' fluid icon='at' type='email' iconPosition='left' placeholder='E-mail Address' />
                            <Form.Group widths='equal'>
                                <Form.Input
                                    required
                                    fluid
                                    fluid icon='user' iconPosition='left'
                                    placeholder='First Name'
                                    type='text'
                                    name='fn'
                                    value={this.state.fn}
                                    onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }}
                                />
                                <Form.Input
                                    required
                                    fluid
                                    placeholder='Last Name'
                                    type='text'
                                    name='ln'
                                    value={this.state.ln}
                                    onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }}
                                />
                            </Form.Group>
                            <Form.Input value={this.state.phone} name='phone' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} required fluid icon='phone' iconPosition='left' type='tel' pattern="^[+94][0-9]{11,11}$" placeholder='Mobile Number | eg:- +94700000000' />
                            <Form.Input value={this.state.amount} name='amount' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} required icon='money bill alternate' min="0.01" type='number' step=".01" fluid iconPosition='left' placeholder='Payment Value' />
                            <Form.Input value={this.state.reference} name='reference' onChange={(e) => { this.setState({ [e.target.name]: e.target.value }) }} required icon='comment outline' type='text' fluid iconPosition='left' pattern=".{10,20}" length="20" required title="The reference should between minimum 10 characters and maximim 20" placeholder='Payment Referance | eg:- INVOICE001' />

                            <Form.Checkbox
                                inline
                                checked
                                label='I agree to the terms and conditions'
                                required
                            />

                            <Button disabled={this.state.buttonDisabled} color='teal' fluid size='large'>
                                Proceed
                            </Button>
                        </Segment>
                    </Form>
                    <Message>
                        <Grid>
                            <Grid.Column width={8} verticalAlign="middle">
                                <center><Image src={'https://www.onepay.lk/assets/images/onepay_logo.png'} size="tiny" /></center>
                            </Grid.Column>
                            <Grid.Column width={8} verticalAlign="middle">
                                Powered By <a href='https://onepay.lk/'>OnePay</a>
                            </Grid.Column>
                        </Grid>
                    </Message>
                </Grid.Column>
            </Grid>
        );
    }
}

const mstp = (state) => {
    return state;
}

const mdtp = (dispatch) => {
    return {
        // dispatching plain actions
        paymetRequest: (requestData) => dispatch(paymetRequest(requestData)),
    }
}

export default connect(mstp, mdtp)(PaymentComponent);