import logo from './logo.svg';
import './App.css';
import PaymentComponent from './PaymentComponent';

function App() {
  return (
    <PaymentComponent />
  );
}

export default App;
